import React from 'react';
import { Layout } from '../components/Layout';
import { Masked } from '../components/Masked';
import background from '../assets/background.webm';
import dcdc from '../assets/dcdc_assetexported.png';
import meet from '../assets/minimal-mockup-Recovered_06.jpg';
import Ben from '../assets/founder/photos/720_bw_ben.jpg';
import Elma from '../assets/founder/photos/720_bw_elma.jpg';
import James from '../assets/founder/photos/720_bw_james.jpg';
import Rhys from '../assets/founder/photos/720_bw_rhys.jpg';
import { TopImageWrapper, TopImage, BigLogo, SmallLogo, Tagline } from '../components/Layout/styled';


const IndexPage = () => (
  <Layout>
    
    <TopImageWrapper>
      <TopImage src={Ben}></TopImage>
      <TopImage src={Elma}></TopImage>
      <TopImage src={James}></TopImage>
      <TopImage src={Rhys}></TopImage>

      <BigLogo>
        <Masked style={{position:"absolute", height:"25%", top:"35%", left: "16px"}}></Masked>
      </BigLogo>
      <Tagline style={{position:"absolute", top:"calc(60% + 10px)"}}>the platform cooperative for cooperatives</Tagline>    
    
    </TopImageWrapper>
      
  </Layout>
);

export default IndexPage;
